const LocationReviewsData = [
{ "date": "2020-08-20", "review_rate": 4.8, "customer_name": "Karen Smith", "service_type": "Masonry Repair", "tech_name": "Sam W.", "review": "Our chimney needed masonry repair, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Anaheim" },
{ "date": "2023-10-25", "review_rate": 5.0, "customer_name": "Mike Jones", "service_type": "Chimney Draft Issues", "tech_name": "John D.", "review": "We had toll in for chimney draft issues after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Anaheim" },
{ "date": "2020-01-26", "review_rate": 4.7, "customer_name": "Christopher Moore", "service_type": "Chimney Animal Removal", "tech_name": "Tom B.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney animal removal. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Anaheim" },
{ "date": "2023-05-27", "review_rate": 5.0, "customer_name": "Charles Taylor", "service_type": "Chimney Maintenance", "tech_name": "Mike S.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney maintenance. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Anaheim" },
{ "date": "2018-12-25", "review_rate": 4.6, "customer_name": "Thomas Wilson", "service_type": "Chimney Liner Installation", "tech_name": "Tom B.", "review": "Our chimney needed chimney liner installation, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Anaheim" },
{ "date": "2020-06-11", "review_rate": 4.8, "customer_name": "Christopher Jones", "service_type": "Chimneyp Installation", "tech_name": "Steve R.", "review": "We had toll in for chimneyp installation after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Anaheim" },
{ "date": "2023-11-16", "review_rate": 4.8, "customer_name": "Susan Brown", "service_type": "Chimney Cleaning", "tech_name": "Sam W.", "review": "I was impressed with the quality of service when I needed chimney cleaning. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2022-03-14", "review_rate": 5.0, "customer_name": "Elizabeth Moore", "service_type": "Fireplace Cleaning", "tech_name": "Mike S.", "review": "After noticing some performance issues with our chimney, we hired a local service for fireplace cleaning. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Anaheim" },
{ "date": "2018-09-20", "review_rate": 4.6, "customer_name": "Jessica Williams", "service_type": "Creosote Removal", "tech_name": "Sam W.", "review": "I had some concerns with my chimney, so Illed for creosote removal. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Anaheim" },
{ "date": "2020-10-15", "review_rate": 4.8, "customer_name": "Charles Miller", "service_type": "Chimney Cleaning", "tech_name": "Sam W.", "review": "I was impressed with the quality of service when I needed chimney cleaning. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2020-01-14", "review_rate": 4.7, "customer_name": "Mary Johnson", "service_type": "Chimney Cleaning", "tech_name": "John D.", "review": "We had toll in for chimney cleaning after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Anaheim" },
{ "date": "2020-01-17", "review_rate": 4.6, "customer_name": "Susan Williams", "service_type": "Chimney Repointing", "tech_name": "Jake P.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney repointing. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Anaheim" },
{ "date": "2019-02-08", "review_rate": 4.8, "customer_name": "William Smith", "service_type": "Chimney Odor Elimination", "tech_name": "Mike S.", "review": "I had some concerns with my chimney, so Illed for chimney odor elimination. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Anaheim" },
{ "date": "2022-08-25", "review_rate": 4.9, "customer_name": "Susan Jones", "service_type": "Chimney Repair", "tech_name": "Jake P.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney repair. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Anaheim" },
{ "date": "2020-06-06", "review_rate": 4.9, "customer_name": "Susan Jones", "service_type": "Chimney Animal Removal", "tech_name": "John D.", "review": "I had some concerns with my chimney, so Illed for chimney animal removal. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Anaheim" },
{ "date": "2019-01-22", "review_rate": 4.9, "customer_name": "William Brown", "service_type": "Chimney Waterproofing", "tech_name": "Jake P.", "review": "I was impressed with the quality of service when I needed chimney waterproofing. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2020-09-01", "review_rate": 5.0, "customer_name": "William Wilson", "service_type": "Smoke Chamber Repair", "tech_name": "John D.", "review": "I had some concerns with my chimney, so Illed for smoke chamber repair. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Anaheim" },
{ "date": "2022-04-26", "review_rate": 4.9, "customer_name": "Jessica Wilson", "service_type": "Chimney Inspection", "tech_name": "Jake P.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney inspection. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Anaheim" },
{ "date": "2020-01-04", "review_rate": 5.0, "customer_name": "David Taylor", "service_type": "Chimney Repair", "tech_name": "Steve R.", "review": "We had toll in for chimney repair after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Anaheim" },
{ "date": "2018-12-13", "review_rate": 4.7, "customer_name": "David Davis", "service_type": "Chimney Draft Issues", "tech_name": "Mike S.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney draft issues. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Anaheim" },
{ "date": "2022-10-14", "review_rate": 4.7, "customer_name": "Richard Miller", "service_type": "Chimney Leak Detection", "tech_name": "Sam W.", "review": "Our chimney needed chimney leak detection, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Anaheim" },
{ "date": "2018-01-04", "review_rate": 4.8, "customer_name": "Nancy Taylor", "service_type": "Chimney Repointing", "tech_name": "Steve R.", "review": "We had toll in for chimney repointing after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Anaheim" },
{ "date": "2020-07-18", "review_rate": 4.6, "customer_name": "Jessica Moore", "service_type": "Chimney Relining", "tech_name": "John D.", "review": "Our chimney needed chimney relining, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Anaheim" },
{ "date": "2019-08-05", "review_rate": 4.9, "customer_name": "Susan Brown", "service_type": "Fireplace Cleaning", "tech_name": "Tom B.", "review": "Our chimney needed fireplace cleaning, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Anaheim" },
{ "date": "2018-11-04", "review_rate": 4.9, "customer_name": "Nancy Smith", "service_type": "Chimney Draft Issues", "tech_name": "Mike S.", "review": "We had toll in for chimney draft issues after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Anaheim" },
{ "date": "2024-12-19", "review_rate": 4.9, "customer_name": "Sarah Williams", "service_type": "Chimney Liner Installation", "tech_name": "Steve R.", "review": "I was impressed with the quality of service when I needed chimney liner installation. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2021-03-27", "review_rate": 4.8, "customer_name": "Christopher Taylor", "service_type": "Chimney Animal Removal", "tech_name": "Jake P.", "review": "We had toll in for chimney animal removal after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Anaheim" },
{ "date": "2019-11-10", "review_rate": 4.8, "customer_name": "Robert Brown", "service_type": "Chimney Inspection", "tech_name": "Steve R.", "review": "We had toll in for chimney inspection after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Anaheim" },
{ "date": "2022-01-27", "review_rate": 4.9, "customer_name": "Joseph Brown", "service_type": "Fireplace Damper Repair", "tech_name": "Mike S.", "review": "I was impressed with the quality of service when I needed fireplace damper repair. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2020-10-04", "review_rate": 5.0, "customer_name": "Linda Jones", "service_type": "Fireplace Cleaning", "tech_name": "Jake P.", "review": "I had some concerns with my chimney, so Illed for fireplace cleaning. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Anaheim" },
{ "date": "2024-06-17", "review_rate": 4.9, "customer_name": "Nancy Johnson", "service_type": "Chimney Animal Removal", "tech_name": "Steve R.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney animal removal. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Anaheim" },
{ "date": "2022-11-07", "review_rate": 4.9, "customer_name": "Richard Wilson", "service_type": "Chimney Safety Inspections", "tech_name": "Sam W.", "review": "We had toll in for chimney safety inspections after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Anaheim" },
{ "date": "2018-04-10", "review_rate": 4.9, "customer_name": "Mary Miller", "service_type": "Chimney Leak Detection", "tech_name": "Mike S.", "review": "Our chimney needed chimney leak detection, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Anaheim" },
{ "date": "2023-05-11", "review_rate": 4.9, "customer_name": "Mary Taylor", "service_type": "Chimneyp Installation", "tech_name": "Jake P.", "review": "Our chimney needed chimneyp installation, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Anaheim" },
{ "date": "2024-03-27", "review_rate": 4.7, "customer_name": "Nancy Williams", "service_type": "Chimney Crown Repair", "tech_name": "Tom B.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney crown repair. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Anaheim" },
{ "date": "2024-02-17", "review_rate": 4.9, "customer_name": "James Jones", "service_type": "Chimney Animal Removal", "tech_name": "Mike S.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney animal removal. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Anaheim" },
{ "date": "2020-09-07", "review_rate": 5.0, "customer_name": "Sarah Smith", "service_type": "Chimney Crown Repair", "tech_name": "John D.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney crown repair. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Anaheim" },
{ "date": "2019-04-07", "review_rate": 4.7, "customer_name": "Susan Smith", "service_type": "Chimney Waterproofing", "tech_name": "Tom B.", "review": "We had toll in for chimney waterproofing after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Anaheim" },
{ "date": "2023-09-27", "review_rate": 5.0, "customer_name": "William Johnson", "service_type": "Masonry Repair", "tech_name": "John D.", "review": "I was impressed with the quality of service when I needed masonry repair. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2022-01-01", "review_rate": 5.0, "customer_name": "Patricia Wilson", "service_type": "Fireplace Damper Repair", "tech_name": "Steve R.", "review": "We had toll in for fireplace damper repair after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Anaheim" },
{ "date": "2021-04-13", "review_rate": 4.9, "customer_name": "Nancy Davis", "service_type": "Chimney Maintenance", "tech_name": "Steve R.", "review": "I had some concerns with my chimney, so Illed for chimney maintenance. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Anaheim" },
{ "date": "2018-12-13", "review_rate": 4.8, "customer_name": "Charles Jones", "service_type": "Fireplace Damper Repair", "tech_name": "Sam W.", "review": "I was impressed with the quality of service when I needed fireplace damper repair. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2024-08-06", "review_rate": 4.7, "customer_name": "Richard Davis", "service_type": "Chimney Draft Issues", "tech_name": "John D.", "review": "I was impressed with the quality of service when I needed chimney draft issues. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2022-09-12", "review_rate": 4.7, "customer_name": "Elizabeth Davis", "service_type": "Chimneyp Installation", "tech_name": "Sam W.", "review": "I was impressed with the quality of service when I needed chimneyp installation. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2022-06-20", "review_rate": 4.6, "customer_name": "James Jones", "service_type": "Chimney Safety Inspections", "tech_name": "Steve R.", "review": "I had some concerns with my chimney, so Illed for chimney safety inspections. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Anaheim" },
{ "date": "2023-05-23", "review_rate": 4.9, "customer_name": "Linda Wilson", "service_type": "Chimney Repointing", "tech_name": "Mike S.", "review": "I had some concerns with my chimney, so Illed for chimney repointing. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Anaheim" },
{ "date": "2021-10-14", "review_rate": 4.9, "customer_name": "Robert Miller", "service_type": "Chimneyp Installation", "tech_name": "Mike S.", "review": "I was impressed with the quality of service when I needed chimneyp installation. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2019-08-07", "review_rate": 4.7, "customer_name": "Susan Taylor", "service_type": "Masonry Repair", "tech_name": "John D.", "review": "I was impressed with the quality of service when I needed masonry repair. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2022-01-25", "review_rate": 4.9, "customer_name": "Mary Brown", "service_type": "Chimney Relining", "tech_name": "Jake P.", "review": "Our chimney needed chimney relining, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Anaheim" },
{ "date": "2024-02-11", "review_rate": 4.6, "customer_name": "Michael Moore", "service_type": "Chimneyp Installation", "tech_name": "John D.", "review": "I was impressed with the quality of service when I needed chimneyp installation. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2020-09-09", "review_rate": 4.6, "customer_name": "James Davis", "service_type": "Chimney Repointing", "tech_name": "John D.", "review": "I had some concerns with my chimney, so Illed for chimney repointing. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Anaheim" },
{ "date": "2019-02-24", "review_rate": 4.6, "customer_name": "Barbara Taylor", "service_type": "Chimney Inspection", "tech_name": "John D.", "review": "I was impressed with the quality of service when I needed chimney inspection. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2019-02-01", "review_rate": 4.8, "customer_name": "Nancy Smith", "service_type": "Chimney Maintenance", "tech_name": "John D.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney maintenance. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Anaheim" },
{ "date": "2019-07-27", "review_rate": 4.9, "customer_name": "Michael Brown", "service_type": "Smoke Chamber Repair", "tech_name": "Jake P.", "review": "After noticing some performance issues with our chimney, we hired a local service for smoke chamber repair. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Anaheim" },
{ "date": "2024-01-11", "review_rate": 4.9, "customer_name": "Linda Williams", "service_type": "Chimney Sweep", "tech_name": "Tom B.", "review": "Our chimney needed chimney sweep, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Anaheim" },
{ "date": "2024-11-07", "review_rate": 4.9, "customer_name": "Sarah Jones", "service_type": "Chimney Odor Elimination", "tech_name": "Sam W.", "review": "I was impressed with the quality of service when I needed chimney odor elimination. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2023-01-15", "review_rate": 4.8, "customer_name": "Nancy Brown", "service_type": "Fireplace Damper Repair", "tech_name": "John D.", "review": "Our chimney needed fireplace damper repair, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Anaheim" },
{ "date": "2022-06-28", "review_rate": 5.0, "customer_name": "Linda Brown", "service_type": "Chimney Maintenance", "tech_name": "Mike S.", "review": "I was impressed with the quality of service when I needed chimney maintenance. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2024-09-21", "review_rate": 4.6, "customer_name": "Elizabeth Johnson", "service_type": "Creosote Removal", "tech_name": "John D.", "review": "I had some concerns with my chimney, so Illed for creosote removal. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Anaheim" },
{ "date": "2023-01-21", "review_rate": 4.9, "customer_name": "David Johnson", "service_type": "Fireplace Cleaning", "tech_name": "Mike S.", "review": "After noticing some performance issues with our chimney, we hired a local service for fireplace cleaning. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Anaheim" },
{ "date": "2021-04-15", "review_rate": 4.6, "customer_name": "David Miller", "service_type": "Chimney Inspection", "tech_name": "Tom B.", "review": "I had some concerns with my chimney, so Illed for chimney inspection. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Anaheim" },
{ "date": "2023-05-23", "review_rate": 4.9, "customer_name": "Karen Williams", "service_type": "Masonry Repair", "tech_name": "Mike S.", "review": "I was impressed with the quality of service when I needed masonry repair. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2019-10-13", "review_rate": 4.9, "customer_name": "Richard Taylor", "service_type": "Chimney Leak Detection", "tech_name": "Jake P.", "review": "I had some concerns with my chimney, so Illed for chimney leak detection. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Anaheim" },
{ "date": "2022-06-03", "review_rate": 4.6, "customer_name": "Christopher Brown", "service_type": "Chimney Animal Removal", "tech_name": "Jake P.", "review": "I had some concerns with my chimney, so Illed for chimney animal removal. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Anaheim" },
{ "date": "2022-07-11", "review_rate": 4.9, "customer_name": "Mary Brown", "service_type": "Fireplace Cleaning", "tech_name": "Tom B.", "review": "We had toll in for fireplace cleaning after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Anaheim" },
{ "date": "2018-07-02", "review_rate": 4.6, "customer_name": "Thomas Moore", "service_type": "Chimney Relining", "tech_name": "Sam W.", "review": "I was impressed with the quality of service when I needed chimney relining. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2018-01-05", "review_rate": 4.9, "customer_name": "Susan Moore", "service_type": "Chimney Waterproofing", "tech_name": "Sam W.", "review": "I had some concerns with my chimney, so Illed for chimney waterproofing. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Anaheim" },
{ "date": "2020-11-04", "review_rate": 4.6, "customer_name": "Jessica Williams", "service_type": "Chimney Waterproofing", "tech_name": "Sam W.", "review": "I had some concerns with my chimney, so Illed for chimney waterproofing. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Anaheim" },
{ "date": "2022-12-26", "review_rate": 4.6, "customer_name": "James Taylor", "service_type": "Chimney Cleaning", "tech_name": "John D.", "review": "We had toll in for chimney cleaning after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Anaheim" },
{ "date": "2021-08-12", "review_rate": 4.9, "customer_name": "Jessica Jones", "service_type": "Masonry Repair", "tech_name": "Steve R.", "review": "I was impressed with the quality of service when I needed masonry repair. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2021-09-07", "review_rate": 4.7, "customer_name": "Thomas Jones", "service_type": "Chimney Repointing", "tech_name": "Jake P.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney repointing. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Anaheim" },
{ "date": "2024-10-27", "review_rate": 4.6, "customer_name": "James Taylor", "service_type": "Chimney Repointing", "tech_name": "Jake P.", "review": "Our chimney needed chimney repointing, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Anaheim" },
{ "date": "2019-06-19", "review_rate": 4.8, "customer_name": "David Brown", "service_type": "Chimney Cleaning", "tech_name": "Mike S.", "review": "I had some concerns with my chimney, so Illed for chimney cleaning. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Anaheim" },
{ "date": "2021-03-05", "review_rate": 4.7, "customer_name": "Linda Johnson", "service_type": "Chimney Safety Inspections", "tech_name": "Steve R.", "review": "We had toll in for chimney safety inspections after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Anaheim" },
{ "date": "2019-09-27", "review_rate": 4.9, "customer_name": "Thomas Jones", "service_type": "Chimney Liner Installation", "tech_name": "John D.", "review": "We had toll in for chimney liner installation after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Anaheim" },
{ "date": "2022-02-15", "review_rate": 4.7, "customer_name": "Elizabeth Smith", "service_type": "Smoke Chamber Repair", "tech_name": "Tom B.", "review": "After noticing some performance issues with our chimney, we hired a local service for smoke chamber repair. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Anaheim" },
{ "date": "2020-04-10", "review_rate": 4.8, "customer_name": "David Davis", "service_type": "Chimney Draft Issues", "tech_name": "Jake P.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney draft issues. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Anaheim" },
{ "date": "2021-04-02", "review_rate": 4.8, "customer_name": "Susan Johnson", "service_type": "Chimney Crown Repair", "tech_name": "Jake P.", "review": "We had toll in for chimney crown repair after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Anaheim" },
{ "date": "2024-03-17", "review_rate": 4.7, "customer_name": "Susan Jones", "service_type": "Fireplace Cleaning", "tech_name": "Mike S.", "review": "I was impressed with the quality of service when I needed fireplace cleaning. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2024-12-02", "review_rate": 4.9, "customer_name": "Sarah Jones", "service_type": "Chimney Repointing", "tech_name": "Tom B.", "review": "Our chimney needed chimney repointing, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Anaheim" },
{ "date": "2020-06-14", "review_rate": 4.9, "customer_name": "Thomas Moore", "service_type": "Fireplace Damper Repair", "tech_name": "Mike S.", "review": "We had toll in for fireplace damper repair after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Anaheim" },
{ "date": "2018-01-04", "review_rate": 4.7, "customer_name": "Sarah Wilson", "service_type": "Creosote Removal", "tech_name": "Mike S.", "review": "Our chimney needed creosote removal, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Anaheim" },
{ "date": "2023-02-21", "review_rate": 4.8, "customer_name": "William Johnson", "service_type": "Chimney Waterproofing", "tech_name": "Steve R.", "review": "I was impressed with the quality of service when I needed chimney waterproofing. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Anaheim" },
{ "date": "2022-05-10", "review_rate": 4.9, "customer_name": "Thomas Jones", "service_type": "Chimney Waterproofing", "tech_name": "Sam W.", "review": "I had some concerns with my chimney, so Illed for chimney waterproofing. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Anaheim" },
{ "date": "2018-10-03", "review_rate": 4.9, "customer_name": "James Jones", "service_type": "Chimney Waterproofing", "tech_name": "Mike S.", "review": "We had toll in for chimney waterproofing after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Anaheim" },
{ "date": "2020-10-18", "review_rate": 4.8, "customer_name": "Nancy Moore", "service_type": "Chimney Cleaning", "tech_name": "John D.", "review": "Our chimney needed chimney cleaning, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Anaheim" },
{ "date": "2022-02-03", "review_rate": 4.7, "customer_name": "Michael Jones", "service_type": "Chimney Cleaning", "tech_name": "Mike S.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney cleaning. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Anaheim" },
{ "date": "2019-09-11", "review_rate": 5.0, "customer_name": "Elizabeth Williams", "service_type": "Chimney Cleaning", "tech_name": "Steve R.", "review": "Our chimney needed chimney cleaning, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Anaheim" },
{ "date": "2020-08-05", "review_rate": 4.8, "customer_name": "Patricia Jones", "service_type": "Fireplace Cleaning", "tech_name": "Steve R.", "review": "I had some concerns with my chimney, so Illed for fireplace cleaning. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Anaheim" },
{ "date": "2021-06-18", "review_rate": 4.8, "customer_name": "Jessica Moore", "service_type": "Chimneyp Installation", "tech_name": "Jake P.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimneyp installation. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Anaheim" },
{ "date": "2022-03-20", "review_rate": 4.9, "customer_name": "Nancy Miller", "service_type": "Chimney Liner Installation", "tech_name": "Mike S.", "review": "We had toll in for chimney liner installation inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2022-01-14", "review_rate": 4.7, "customer_name": "Christopher Williams", "service_type": "Fireplace Cleaning", "tech_name": "Sam W.", "review": "I had some concerns with my chimney, so Illed for fireplace cleaning inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2022-01-12", "review_rate": 4.6, "customer_name": "Patricia Brown", "service_type": "Chimney Leak Detection", "tech_name": "Steve R.", "review": "We had toll in for chimney leak detection inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2020-04-05", "review_rate": 4.8, "customer_name": "William Miller", "service_type": "Chimney Maintenance", "tech_name": "Mike S.", "review": "I was impressed with the quality of service when I needed chimney maintenance inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2023-11-13", "review_rate": 4.8, "customer_name": "Joseph Jones", "service_type": "Masonry Repair", "tech_name": "Steve R.", "review": "I had some concerns with my chimney, so Illed for masonry repair inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2023-09-01", "review_rate": 4.9, "customer_name": "Thomas Taylor", "service_type": "Chimney Safety Inspections", "tech_name": "John D.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney safety inspections inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2024-01-23", "review_rate": 4.8, "customer_name": "Susan Williams", "service_type": "Creosote Removal", "tech_name": "Steve R.", "review": "I was impressed with the quality of service when I needed creosote removal inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2018-03-14", "review_rate": 4.9, "customer_name": "Nancy Taylor", "service_type": "Fireplace Damper Repair", "tech_name": "Steve R.", "review": "I had some concerns with my chimney, so Illed for fireplace damper repair inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2023-09-22", "review_rate": 4.8, "customer_name": "Susan Davis", "service_type": "Chimney Liner Installation", "tech_name": "Sam W.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney liner installation inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2023-01-04", "review_rate": 4.9, "customer_name": "Joseph Jones", "service_type": "Chimney Sweep", "tech_name": "Tom B.", "review": "I was impressed with the quality of service when I needed chimney sweep inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2024-04-15", "review_rate": 4.8, "customer_name": "Sarah Wilson", "service_type": "Chimney Waterproofing", "tech_name": "Sam W.", "review": "Our chimney needed chimney waterproofing, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2023-07-22", "review_rate": 4.8, "customer_name": "Sarah Williams", "service_type": "Chimneyp Installation", "tech_name": "Sam W.", "review": "I was impressed with the quality of service when I needed chimneyp installation inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2023-04-24", "review_rate": 4.9, "customer_name": "Karen Miller", "service_type": "Chimney Waterproofing", "tech_name": "Jake P.", "review": "We had toll in for chimney waterproofing inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2019-01-21", "review_rate": 4.8, "customer_name": "Christopher Moore", "service_type": "Chimney Inspection", "tech_name": "Jake P.", "review": "I had some concerns with my chimney, so Illed for chimney inspection inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2021-08-05", "review_rate": 4.8, "customer_name": "Robert Jones", "service_type": "Smoke Chamber Repair", "tech_name": "John D.", "review": "Our chimney needed smoke chamber repair, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2024-06-12", "review_rate": 4.9, "customer_name": "Sarah Williams", "service_type": "Creosote Removal", "tech_name": "John D.", "review": "I was impressed with the quality of service when I needed creosote removal inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2020-03-23", "review_rate": 4.9, "customer_name": "Charles Jones", "service_type": "Fireplace Damper Repair", "tech_name": "John D.", "review": "I had some concerns with my chimney, so Illed for fireplace damper repair inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2020-08-08", "review_rate": 4.8, "customer_name": "Christopher Davis", "service_type": "Chimney Cleaning", "tech_name": "Jake P.", "review": "I was impressed with the quality of service when I needed chimney cleaning inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2020-05-12", "review_rate": 5.0, "customer_name": "Christopher Williams", "service_type": "Chimney Crown Repair", "tech_name": "Sam W.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney crown repair inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2021-04-24", "review_rate": 5.0, "customer_name": "Mary Smith", "service_type": "Smoke Chamber Repair", "tech_name": "Sam W.", "review": "Our chimney needed smoke chamber repair, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2020-08-23", "review_rate": 4.9, "customer_name": "Christopher Johnson", "service_type": "Fireplace Cleaning", "tech_name": "Steve R.", "review": "Our chimney needed fireplace cleaning, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2023-08-14", "review_rate": 4.9, "customer_name": "Karen Miller", "service_type": "Fireplace Cleaning", "tech_name": "Tom B.", "review": "Our chimney needed fireplace cleaning, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2020-04-17", "review_rate": 4.8, "customer_name": "Joseph Jones", "service_type": "Fireplace Damper Repair", "tech_name": "John D.", "review": "After noticing some performance issues with our chimney, we hired a local service for fireplace damper repair inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2022-03-19", "review_rate": 4.6, "customer_name": "Barbara Williams", "service_type": "Chimney Waterproofing", "tech_name": "Sam W.", "review": "I had some concerns with my chimney, so Illed for chimney waterproofing inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2018-10-12", "review_rate": 4.6, "customer_name": "Robert Brown", "service_type": "Chimney Draft Issues", "tech_name": "Tom B.", "review": "Our chimney needed chimney draft issues, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2021-09-23", "review_rate": 4.8, "customer_name": "William Jones", "service_type": "Chimney Cleaning", "tech_name": "John D.", "review": "I had some concerns with my chimney, so Illed for chimney cleaning inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2019-01-15", "review_rate": 4.9, "customer_name": "Jessica Williams", "service_type": "Chimney Odor Elimination", "tech_name": "Mike S.", "review": "I was impressed with the quality of service when I needed chimney odor elimination inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2019-11-15", "review_rate": 4.7, "customer_name": "Karen Taylor", "service_type": "Chimney Liner Installation", "tech_name": "Mike S.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney liner installation inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2021-10-04", "review_rate": 4.8, "customer_name": "Christopher Williams", "service_type": "Fireplace Damper Repair", "tech_name": "Sam W.", "review": "After noticing some performance issues with our chimney, we hired a local service for fireplace damper repair inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2019-02-12", "review_rate": 4.8, "customer_name": "Thomas Wilson", "service_type": "Chimney Waterproofing", "tech_name": "John D.", "review": "I had some concerns with my chimney, so Illed for chimney waterproofing inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2024-01-01", "review_rate": 4.9, "customer_name": "Sarah Davis", "service_type": "Creosote Removal", "tech_name": "Sam W.", "review": "Our chimney needed creosote removal, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2021-05-20", "review_rate": 4.8, "customer_name": "Joseph Jones", "service_type": "Chimney Repair", "tech_name": "Steve R.", "review": "We had toll in for chimney repair inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2020-08-15", "review_rate": 4.9, "customer_name": "Elizabeth Johnson", "service_type": "Fireplace Damper Repair", "tech_name": "Jake P.", "review": "Our chimney needed fireplace damper repair, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2020-05-06", "review_rate": 4.8, "customer_name": "David Davis", "service_type": "Chimneyp Installation", "tech_name": "John D.", "review": "I was impressed with the quality of service when I needed chimneyp installation inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2021-10-24", "review_rate": 4.7, "customer_name": "Christopher Johnson", "service_type": "Chimney Cleaning", "tech_name": "Mike S.", "review": "Our chimney needed chimney cleaning, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2019-12-17", "review_rate": 5.0, "customer_name": "Linda Smith", "service_type": "Chimney Liner Installation", "tech_name": "Mike S.", "review": "Our chimney needed chimney liner installation, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2020-04-20", "review_rate": 4.6, "customer_name": "Thomas Taylor", "service_type": "Chimney Safety Inspections", "tech_name": "John D.", "review": "I had some concerns with my chimney, so Illed for chimney safety inspections inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2020-04-16", "review_rate": 4.7, "customer_name": "Sarah Williams", "service_type": "Chimney Waterproofing", "tech_name": "Mike S.", "review": "We had toll in for chimney waterproofing inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2024-01-20", "review_rate": 4.9, "customer_name": "Richard Miller", "service_type": "Chimney Repointing", "tech_name": "Steve R.", "review": "I was impressed with the quality of service when I needed chimney repointing inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2022-10-05", "review_rate": 4.8, "customer_name": "Nancy Jones", "service_type": "Chimney Inspection", "tech_name": "Mike S.", "review": "I had some concerns with my chimney, so Illed for chimney inspection inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2019-07-20", "review_rate": 4.7, "customer_name": "Sarah Williams", "service_type": "Fireplace Damper Repair", "tech_name": "Mike S.", "review": "I was impressed with the quality of service when I needed fireplace damper repair inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2021-02-23", "review_rate": 4.9, "customer_name": "Linda Jones", "service_type": "Chimney Crown Repair", "tech_name": "Mike S.", "review": "Our chimney needed chimney crown repair, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2023-11-15", "review_rate": 4.6, "customer_name": "Sarah Jones", "service_type": "Chimney Sweep", "tech_name": "Mike S.", "review": "Our chimney needed chimney sweep, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2019-02-10", "review_rate": 5.0, "customer_name": "Nancy Miller", "service_type": "Chimney Cleaning", "tech_name": "Jake P.", "review": "Our chimney needed chimney cleaning, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2021-10-15", "review_rate": 4.7, "customer_name": "Jessica Wilson", "service_type": "Chimney Inspection", "tech_name": "Steve R.", "review": "I had some concerns with my chimney, so Illed for chimney inspection inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2018-12-28", "review_rate": 5.0, "customer_name": "Barbara Jones", "service_type": "Chimneyp Installation", "tech_name": "John D.", "review": "I had some concerns with my chimney, so Illed for chimneyp installation inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2023-09-16", "review_rate": 4.9, "customer_name": "Karen Davis", "service_type": "Chimney Crown Repair", "tech_name": "Jake P.", "review": "I had some concerns with my chimney, so Illed for chimney crown repair inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2018-10-12", "review_rate": 4.6, "customer_name": "Mary Smith", "service_type": "Chimney Crown Repair", "tech_name": "Jake P.", "review": "We had toll in for chimney crown repair inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2021-11-25", "review_rate": 4.7, "customer_name": "Joseph Johnson", "service_type": "Chimney Leak Detection", "tech_name": "Jake P.", "review": "We had toll in for chimney leak detection inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2024-03-26", "review_rate": 4.9, "customer_name": "Patricia Brown", "service_type": "Chimney Repair", "tech_name": "Mike S.", "review": "Our chimney needed chimney repair, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2022-06-04", "review_rate": 4.7, "customer_name": "Robert Williams", "service_type": "Chimney Relining", "tech_name": "Steve R.", "review": "Our chimney needed chimney relining, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2020-01-07", "review_rate": 5.0, "customer_name": "James Smith", "service_type": "Chimney Odor Elimination", "tech_name": "John D.", "review": "I had some concerns with my chimney, so Illed for chimney odor elimination inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2019-09-23", "review_rate": 4.8, "customer_name": "Linda Jones", "service_type": "Chimney Safety Inspections", "tech_name": "Sam W.", "review": "I had some concerns with my chimney, so Illed for chimney safety inspections inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2018-07-04", "review_rate": 4.8, "customer_name": "Mary Brown", "service_type": "Creosote Removal", "tech_name": "Tom B.", "review": "I had some concerns with my chimney, so Illed for creosote removal inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2018-04-27", "review_rate": 4.9, "customer_name": "Patricia Brown", "service_type": "Chimney Sweep", "tech_name": "Mike S.", "review": "Our chimney needed chimney sweep, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2021-06-07", "review_rate": 4.8, "customer_name": "James Miller", "service_type": "Chimney Crown Repair", "tech_name": "Tom B.", "review": "I had some concerns with my chimney, so Illed for chimney crown repair inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2024-10-14", "review_rate": 4.6, "customer_name": "Linda Brown", "service_type": "Chimney Draft Issues", "tech_name": "Steve R.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney draft issues inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2024-07-10", "review_rate": 4.7, "customer_name": "William Johnson", "service_type": "Chimney Odor Elimination", "tech_name": "Mike S.", "review": "Our chimney needed chimney odor elimination, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2018-10-25", "review_rate": 4.7, "customer_name": "Joseph Wilson", "service_type": "Chimney Repointing", "tech_name": "Steve R.", "review": "I was impressed with the quality of service when I needed chimney repointing inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2018-12-11", "review_rate": 4.7, "customer_name": "David Williams", "service_type": "Chimney Crown Repair", "tech_name": "Steve R.", "review": "Our chimney needed chimney crown repair, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2019-02-17", "review_rate": 4.9, "customer_name": "Susan Johnson", "service_type": "Chimney Liner Installation", "tech_name": "Mike S.", "review": "We had toll in for chimney liner installation inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2018-10-26", "review_rate": 4.9, "customer_name": "Jessica Davis", "service_type": "Chimney Cleaning", "tech_name": "Sam W.", "review": "I was impressed with the quality of service when I needed chimney cleaning inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2021-08-19", "review_rate": 4.9, "customer_name": "William Moore", "service_type": "Chimney Inspection", "tech_name": "John D.", "review": "We had toll in for chimney inspection inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2022-02-23", "review_rate": 4.9, "customer_name": "Linda Wilson", "service_type": "Chimney Safety Inspections", "tech_name": "Jake P.", "review": "We had toll in for chimney safety inspections inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2024-12-12", "review_rate": 4.8, "customer_name": "Charles Davis", "service_type": "Chimney Waterproofing", "tech_name": "Tom B.", "review": "I was impressed with the quality of service when I needed chimney waterproofing inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2020-09-06", "review_rate": 4.7, "customer_name": "Linda Miller", "service_type": "Chimney Waterproofing", "tech_name": "John D.", "review": "I was impressed with the quality of service when I needed chimney waterproofing inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2022-05-17", "review_rate": 5.0, "customer_name": "Robert Moore", "service_type": "Chimney Repair", "tech_name": "John D.", "review": "Our chimney needed chimney repair, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2023-12-08", "review_rate": 4.9, "customer_name": "Michael Smith", "service_type": "Chimney Leak Detection", "tech_name": "Mike S.", "review": "I had some concerns with my chimney, so Illed for chimney leak detection inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2022-01-25", "review_rate": 4.9, "customer_name": "Linda Johnson", "service_type": "Chimneyp Installation", "tech_name": "Steve R.", "review": "We had toll in for chimneyp installation inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2021-10-18", "review_rate": 4.9, "customer_name": "Karen Johnson", "service_type": "Fireplace Damper Repair", "tech_name": "Steve R.", "review": "I was impressed with the quality of service when I needed fireplace damper repair inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2019-10-19", "review_rate": 4.9, "customer_name": "Charles Smith", "service_type": "Creosote Removal", "tech_name": "Jake P.", "review": "We had toll in for creosote removal inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2020-04-03", "review_rate": 4.8, "customer_name": "Christopher Jones", "service_type": "Fireplace Damper Repair", "tech_name": "Steve R.", "review": "We had toll in for fireplace damper repair inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2024-06-23", "review_rate": 5.0, "customer_name": "Nancy Jones", "service_type": "Chimney Repair", "tech_name": "Tom B.", "review": "I had some concerns with my chimney, so Illed for chimney repair inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2024-05-09", "review_rate": 4.8, "customer_name": "Barbara Brown", "service_type": "Chimney Relining", "tech_name": "Tom B.", "review": "I had some concerns with my chimney, so Illed for chimney relining inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2021-08-08", "review_rate": 4.9, "customer_name": "Thomas Taylor", "service_type": "Fireplace Damper Repair", "tech_name": "Mike S.", "review": "We had toll in for fireplace damper repair inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2023-06-26", "review_rate": 4.7, "customer_name": "Barbara Moore", "service_type": "Chimney Crown Repair", "tech_name": "Mike S.", "review": "We had toll in for chimney crown repair inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2018-08-28", "review_rate": 4.8, "customer_name": "David Wilson", "service_type": "Chimney Leak Detection", "tech_name": "Jake P.", "review": "Our chimney needed chimney leak detection, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2020-06-22", "review_rate": 4.7, "customer_name": "Jessica Williams", "service_type": "Chimney Inspection", "tech_name": "Sam W.", "review": "Our chimney needed chimney inspection, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2023-05-12", "review_rate": 4.9, "customer_name": "Barbara Jones", "service_type": "Chimney Maintenance", "tech_name": "Tom B.", "review": "Our chimney needed chimney maintenance, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2022-09-03", "review_rate": 4.9, "customer_name": "Linda Smith", "service_type": "Chimney Inspection", "tech_name": "Steve R.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney inspection inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2021-12-06", "review_rate": 4.8, "customer_name": "Sarah Johnson", "service_type": "Chimney Relining", "tech_name": "Jake P.", "review": "Our chimney needed chimney relining, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2024-03-21", "review_rate": 4.9, "customer_name": "David Brown", "service_type": "Chimney Leak Detection", "tech_name": "John D.", "review": "I had some concerns with my chimney, so Illed for chimney leak detection inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2021-03-17", "review_rate": 4.9, "customer_name": "Mary Taylor", "service_type": "Chimney Draft Issues", "tech_name": "Tom B.", "review": "We had toll in for chimney draft issues inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2023-10-21", "review_rate": 4.7, "customer_name": "David Moore", "service_type": "Smoke Chamber Repair", "tech_name": "Mike S.", "review": "I had some concerns with my chimney, so Illed for smoke chamber repair inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2022-06-21", "review_rate": 4.8, "customer_name": "Christopher Moore", "service_type": "Chimney Maintenance", "tech_name": "Sam W.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney maintenance inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2024-07-07", "review_rate": 5.0, "customer_name": "Jessica Johnson", "service_type": "Chimney Draft Issues", "tech_name": "John D.", "review": "Our chimney needed chimney draft issues, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2024-09-13", "review_rate": 4.9, "customer_name": "William Johnson", "service_type": "Chimney Inspection", "tech_name": "Jake P.", "review": "I had some concerns with my chimney, so Illed for chimney inspection inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2022-04-06", "review_rate": 4.8, "customer_name": "Elizabeth Williams", "service_type": "Chimney Repair", "tech_name": "Mike S.", "review": "Our chimney needed chimney repair, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2022-10-02", "review_rate": 4.9, "customer_name": "James Wilson", "service_type": "Fireplace Damper Repair", "tech_name": "Jake P.", "review": "After noticing some performance issues with our chimney, we hired a local service for fireplace damper repair inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2020-10-22", "review_rate": 4.8, "customer_name": "Mary Miller", "service_type": "Smoke Chamber Repair", "tech_name": "Jake P.", "review": "After noticing some performance issues with our chimney, we hired a local service for smoke chamber repair inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2018-06-11", "review_rate": 4.9, "customer_name": "Richard Wilson", "service_type": "Chimney Animal Removal", "tech_name": "Jake P.", "review": "Our chimney needed chimney animal removal, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2020-04-04", "review_rate": 4.6, "customer_name": "Sarah Jones", "service_type": "Chimney Leak Detection", "tech_name": "Mike S.", "review": "We had toll in for chimney leak detection inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2021-02-18", "review_rate": 4.7, "customer_name": "David Davis", "service_type": "Chimney Safety Inspections", "tech_name": "Steve R.", "review": "I had some concerns with my chimney, so Illed for chimney safety inspections inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2021-06-07", "review_rate": 4.6, "customer_name": "James Jones", "service_type": "Chimney Liner Installation", "tech_name": "John D.", "review": "We had toll in for chimney liner installation inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2023-03-11", "review_rate": 4.8, "customer_name": "Joseph Smith", "service_type": "Creosote Removal", "tech_name": "John D.", "review": "I was impressed with the quality of service when I needed creosote removal inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2020-09-18", "review_rate": 4.8, "customer_name": "Michael Miller", "service_type": "Chimney Repointing", "tech_name": "Steve R.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney repointing inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2018-06-12", "review_rate": 4.9, "customer_name": "Christopher Taylor", "service_type": "Chimney Odor Elimination", "tech_name": "Jake P.", "review": "I had some concerns with my chimney, so Illed for chimney odor elimination inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2024-10-04", "review_rate": 4.7, "customer_name": "Joseph Miller", "service_type": "Chimney Animal Removal", "tech_name": "Sam W.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney animal removal inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2022-10-01", "review_rate": 4.8, "customer_name": "Linda Davis", "service_type": "Chimney Sweep", "tech_name": "John D.", "review": "We had toll in for chimney sweep inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2023-02-01", "review_rate": 5.0, "customer_name": "Karen Jones", "service_type": "Chimney Odor Elimination", "tech_name": "Steve R.", "review": "We had toll in for chimney odor elimination inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2024-02-28", "review_rate": 4.6, "customer_name": "Charles Johnson", "service_type": "Fireplace Damper Repair", "tech_name": "Jake P.", "review": "We had toll in for fireplace damper repair inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2023-07-14", "review_rate": 4.7, "customer_name": "Nancy Jones", "service_type": "Chimney Odor Elimination", "tech_name": "Sam W.", "review": "I was impressed with the quality of service when I needed chimney odor elimination inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2021-10-16", "review_rate": 4.8, "customer_name": "Michael Brown", "service_type": "Chimney Inspection", "tech_name": "Tom B.", "review": "I was impressed with the quality of service when I needed chimney inspection inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2022-02-15", "review_rate": 5.0, "customer_name": "Michael Miller", "service_type": "Creosote Removal", "tech_name": "Steve R.", "review": "We had toll in for creosote removal inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2018-11-04", "review_rate": 4.7, "customer_name": "Mary Moore", "service_type": "Chimneyp Installation", "tech_name": "Tom B.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimneyp installation inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2023-05-08", "review_rate": 4.7, "customer_name": "Susan Brown", "service_type": "Smoke Chamber Repair", "tech_name": "Tom B.", "review": "I had some concerns with my chimney, so Illed for smoke chamber repair inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2023-05-13", "review_rate": 4.8, "customer_name": "Richard Brown", "service_type": "Smoke Chamber Repair", "tech_name": "Steve R.", "review": "After noticing some performance issues with our chimney, we hired a local service for smoke chamber repair inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2018-05-13", "review_rate": 4.7, "customer_name": "David Brown", "service_type": "Chimney Crown Repair", "tech_name": "Jake P.", "review": "Our chimney needed chimney crown repair, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2020-09-21", "review_rate": 5.0, "customer_name": "William Davis", "service_type": "Chimney Sweep", "tech_name": "Jake P.", "review": "I had some concerns with my chimney, so Illed for chimney sweep inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2019-01-11", "review_rate": 4.7, "customer_name": "Elizabeth Smith", "service_type": "Chimney Leak Detection", "tech_name": "Mike S.", "review": "I had some concerns with my chimney, so Illed for chimney leak detection inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2023-01-11", "review_rate": 4.7, "customer_name": "Robert Wilson", "service_type": "Chimney Relining", "tech_name": "Sam W.", "review": "We had toll in for chimney relining inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2022-05-14", "review_rate": 4.7, "customer_name": "Joseph Smith", "service_type": "Fireplace Damper Repair", "tech_name": "Mike S.", "review": "I had some concerns with my chimney, so Illed for fireplace damper repair inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2022-02-01", "review_rate": 4.6, "customer_name": "Michael Taylor", "service_type": "Fireplace Cleaning", "tech_name": "John D.", "review": "After noticing some performance issues with our chimney, we hired a local service for fireplace cleaning inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2022-05-05", "review_rate": 4.7, "customer_name": "Patricia Wilson", "service_type": "Chimney Crown Repair", "tech_name": "Sam W.", "review": "I was impressed with the quality of service when I needed chimney crown repair inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2019-07-15", "review_rate": 4.7, "customer_name": "David Brown", "service_type": "Chimney Repointing", "tech_name": "Tom B.", "review": "Our chimney needed chimney repointing, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2018-12-06", "review_rate": 4.8, "customer_name": "Sarah Moore", "service_type": "Fireplace Damper Repair", "tech_name": "John D.", "review": "We had toll in for fireplace damper repair inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2018-03-24", "review_rate": 4.7, "customer_name": "Christopher Miller", "service_type": "Creosote Removal", "tech_name": "Steve R.", "review": "After noticing some performance issues with our chimney, we hired a local service for creosote removal inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2018-03-08", "review_rate": 4.7, "customer_name": "James Jones", "service_type": "Chimney Leak Detection", "tech_name": "Mike S.", "review": "I was impressed with the quality of service when I needed chimney leak detection inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2020-09-04", "review_rate": 4.9, "customer_name": "James Moore", "service_type": "Chimney Waterproofing", "tech_name": "Jake P.", "review": "Our chimney needed chimney waterproofing, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2021-05-25", "review_rate": 4.9, "customer_name": "Robert Miller", "service_type": "Chimney Relining", "tech_name": "Jake P.", "review": "I had some concerns with my chimney, so Illed for chimney relining inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2023-01-11", "review_rate": 4.8, "customer_name": "Michael Smith", "service_type": "Chimney Waterproofing", "tech_name": "Tom B.", "review": "Our chimney needed chimney waterproofing, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2021-12-17", "review_rate": 4.9, "customer_name": "Richard Jones", "service_type": "Chimney Relining", "tech_name": "Mike S.", "review": "Our chimney needed chimney relining, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2024-09-26", "review_rate": 4.9, "customer_name": "Mary Miller", "service_type": "Masonry Repair", "tech_name": "Mike S.", "review": "Our chimney needed masonry repair, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2024-07-18", "review_rate": 4.9, "customer_name": "Mary Taylor", "service_type": "Chimney Crown Repair", "tech_name": "John D.", "review": "We had toll in for chimney crown repair inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2024-01-27", "review_rate": 4.7, "customer_name": "William Moore", "service_type": "Chimney Sweep", "tech_name": "Jake P.", "review": "I was impressed with the quality of service when I needed chimney sweep inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2020-08-20", "review_rate": 4.9, "customer_name": "Thomas Williams", "service_type": "Chimney Maintenance", "tech_name": "Steve R.", "review": "We had toll in for chimney maintenance inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2020-08-04", "review_rate": 4.7, "customer_name": "Richard Brown", "service_type": "Chimney Sweep", "tech_name": "Sam W.", "review": "I had some concerns with my chimney, so Illed for chimney sweep inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2023-02-03", "review_rate": 4.7, "customer_name": "Linda Jones", "service_type": "Creosote Removal", "tech_name": "Sam W.", "review": "We had toll in for creosote removal inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2020-07-09", "review_rate": 4.8, "customer_name": "Barbara Wilson", "service_type": "Fireplace Damper Repair", "tech_name": "Steve R.", "review": "I had some concerns with my chimney, so Illed for fireplace damper repair inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2020-03-24", "review_rate": 4.7, "customer_name": "Charles Johnson", "service_type": "Chimney Draft Issues", "tech_name": "Jake P.", "review": "I had some concerns with my chimney, so Illed for chimney draft issues inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2019-04-04", "review_rate": 4.9, "customer_name": "Charles Wilson", "service_type": "Masonry Repair", "tech_name": "Sam W.", "review": "We had toll in for masonry repair inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2020-12-18", "review_rate": 4.8, "customer_name": "David Moore", "service_type": "Fireplace Cleaning", "tech_name": "John D.", "review": "After noticing some performance issues with our chimney, we hired a local service for fireplace cleaning inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2023-10-26", "review_rate": 4.8, "customer_name": "Richard Smith", "service_type": "Masonry Repair", "tech_name": "Tom B.", "review": "We had toll in for masonry repair inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2024-12-03", "review_rate": 4.7, "customer_name": "Joseph Smith", "service_type": "Chimney Inspection", "tech_name": "Jake P.", "review": "I had some concerns with my chimney, so Illed for chimney inspection inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2023-09-17", "review_rate": 4.7, "customer_name": "Michael Moore", "service_type": "Smoke Chamber Repair", "tech_name": "Mike S.", "review": "Our chimney needed smoke chamber repair, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2021-09-16", "review_rate": 4.7, "customer_name": "Michael Smith", "service_type": "Chimney Draft Issues", "tech_name": "Tom B.", "review": "I was impressed with the quality of service when I needed chimney draft issues inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2021-05-28", "review_rate": 4.8, "customer_name": "Joseph Johnson", "service_type": "Chimney Relining", "tech_name": "Mike S.", "review": "Our chimney needed chimney relining, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2023-07-03", "review_rate": 4.9, "customer_name": "Elizabeth Taylor", "service_type": "Fireplace Cleaning", "tech_name": "Tom B.", "review": "After noticing some performance issues with our chimney, we hired a local service for fireplace cleaning inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2022-02-04", "review_rate": 4.9, "customer_name": "Mary Johnson", "service_type": "Chimney Crown Repair", "tech_name": "Jake P.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney crown repair inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2021-01-18", "review_rate": 4.7, "customer_name": "Karen Moore", "service_type": "Chimney Draft Issues", "tech_name": "Mike S.", "review": "I had some concerns with my chimney, so Illed for chimney draft issues inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2018-10-04", "review_rate": 4.9, "customer_name": "Thomas Jones", "service_type": "Chimney Repair", "tech_name": "Tom B.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney repair inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2024-07-27", "review_rate": 4.6, "customer_name": "Barbara Williams", "service_type": "Smoke Chamber Repair", "tech_name": "Steve R.", "review": "After noticing some performance issues with our chimney, we hired a local service for smoke chamber repair inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2023-04-24", "review_rate": 4.6, "customer_name": "Patricia Jones", "service_type": "Chimney Repointing", "tech_name": "Jake P.", "review": "We had toll in for chimney repointing inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2020-11-24", "review_rate": 4.7, "customer_name": "Susan Taylor", "service_type": "Chimney Cleaning", "tech_name": "Sam W.", "review": "We had toll in for chimney cleaning inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2019-10-11", "review_rate": 4.9, "customer_name": "Elizabeth Brown", "service_type": "Masonry Repair", "tech_name": "Sam W.", "review": "I was impressed with the quality of service when I needed masonry repair inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2022-12-25", "review_rate": 5.0, "customer_name": "Joseph Smith", "service_type": "Chimney Leak Detection", "tech_name": "Steve R.", "review": "We had toll in for chimney leak detection inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2023-07-02", "review_rate": 4.7, "customer_name": "Sarah Jones", "service_type": "Chimney Liner Installation", "tech_name": "Jake P.", "review": "I was impressed with the quality of service when I needed chimney liner installation inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2021-08-05", "review_rate": 4.7, "customer_name": "Barbara Taylor", "service_type": "Chimney Leak Detection", "tech_name": "Sam W.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney leak detection inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2018-11-27", "review_rate": 4.8, "customer_name": "Robert Jones", "service_type": "Chimney Maintenance", "tech_name": "Steve R.", "review": "I was impressed with the quality of service when I needed chimney maintenance inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2021-08-25", "review_rate": 4.9, "customer_name": "Nancy Moore", "service_type": "Fireplace Damper Repair", "tech_name": "Steve R.", "review": "I was impressed with the quality of service when I needed fireplace damper repair inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2023-01-15", "review_rate": 4.7, "customer_name": "Charles Moore", "service_type": "Chimney Waterproofing", "tech_name": "Mike S.", "review": "I had some concerns with my chimney, so Illed for chimney waterproofing inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2023-10-25", "review_rate": 5.0, "customer_name": "Sarah Miller", "service_type": "Chimney Waterproofing", "tech_name": "Sam W.", "review": "I had some concerns with my chimney, so Illed for chimney waterproofing inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2023-06-20", "review_rate": 4.6, "customer_name": "Susan Smith", "service_type": "Chimney Safety Inspections", "tech_name": "Jake P.", "review": "I had some concerns with my chimney, so Illed for chimney safety inspections inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2020-06-18", "review_rate": 4.9, "customer_name": "Michael Williams", "service_type": "Chimney Waterproofing", "tech_name": "Tom B.", "review": "Our chimney needed chimney waterproofing, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2023-05-01", "review_rate": 4.8, "customer_name": "Linda Davis", "service_type": "Chimneyp Installation", "tech_name": "Mike S.", "review": "I had some concerns with my chimney, so Illed for chimneyp installation inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2024-01-28", "review_rate": 4.9, "customer_name": "Patricia Miller", "service_type": "Creosote Removal", "tech_name": "Tom B.", "review": "I had some concerns with my chimney, so Illed for creosote removal inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2021-06-12", "review_rate": 4.8, "customer_name": "Linda Moore", "service_type": "Chimney Draft Issues", "tech_name": "Tom B.", "review": "I had some concerns with my chimney, so Illed for chimney draft issues inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2020-02-24", "review_rate": 4.6, "customer_name": "Karen Miller", "service_type": "Chimney Repair", "tech_name": "Steve R.", "review": "I had some concerns with my chimney, so Illed for chimney repair inlabasas. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Calabasas" },
{ "date": "2020-11-22", "review_rate": 4.8, "customer_name": "Barbara Miller", "service_type": "Chimney Cleaning", "tech_name": "Steve R.", "review": "We had toll in for chimney cleaning inlabasas after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Calabasas" },
{ "date": "2020-10-27", "review_rate": 4.8, "customer_name": "Karen Miller", "service_type": "Chimneyp Installation", "tech_name": "John D.", "review": "Our chimney needed chimneyp installation, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2020-07-06", "review_rate": 4.7, "customer_name": "Thomas Brown", "service_type": "Fireplace Cleaning", "tech_name": "Tom B.", "review": "I was impressed with the quality of service when I needed fireplace cleaning inlabasas. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Calabasas" },
{ "date": "2023-11-09", "review_rate": 4.9, "customer_name": "Richard Williams", "service_type": "Chimneyp Installation", "tech_name": "Steve R.", "review": "Our chimney needed chimneyp installation, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2023-12-17", "review_rate": 4.6, "customer_name": "William Moore", "service_type": "Chimney Relining", "tech_name": "Sam W.", "review": "Our chimney needed chimney relining, so we contacted a professional inlabasas. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Calabasas" },
{ "date": "2021-08-23", "review_rate": 4.9, "customer_name": "Barbara Wilson", "service_type": "Chimney Animal Removal", "tech_name": "Sam W.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney animal removal inlabasas. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Calabasas" },
{ "date": "2019-02-07", "review_rate": 4.7, "customer_name": "Susan Taylor", "service_type": "Chimney Waterproofing", "tech_name": "Sam W.", "review": "We had toll in for chimney waterproofing after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Corona" },
{ "date": "2023-02-05", "review_rate": 4.6, "customer_name": "Charles Brown", "service_type": "Chimney Leak Detection", "tech_name": "Sam W.", "review": "I was impressed with the quality of service when I needed chimney leak detection. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Corona" },
{ "date": "2024-09-08", "review_rate": 4.6, "customer_name": "Elizabeth Moore", "service_type": "Chimney Repair", "tech_name": "Mike S.", "review": "I had some concerns with my chimney, so Illed for chimney repair. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Corona" },
{ "date": "2024-09-12", "review_rate": 4.6, "customer_name": "Patricia Moore", "service_type": "Chimney Waterproofing", "tech_name": "Sam W.", "review": "I was impressed with the quality of service when I needed chimney waterproofing. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Corona" },
{ "date": "2020-12-05", "review_rate": 4.8, "customer_name": "Joseph Davis", "service_type": "Chimney Cleaning", "tech_name": "Steve R.", "review": "We had toll in for chimney cleaning after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Corona" },
{ "date": "2024-04-22", "review_rate": 5.0, "customer_name": "Karen Taylor", "service_type": "Chimneyp Installation", "tech_name": "John D.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimneyp installation. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Corona" },
{ "date": "2020-06-26", "review_rate": 5.0, "customer_name": "Sarah Brown", "service_type": "Chimney Cleaning", "tech_name": "Steve R.", "review": "Our chimney needed chimney cleaning, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Corona" },
{ "date": "2021-03-05", "review_rate": 4.6, "customer_name": "Elizabeth Brown", "service_type": "Chimney Leak Detection", "tech_name": "Tom B.", "review": "I was impressed with the quality of service when I needed chimney leak detection. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Corona" },
{ "date": "2021-12-14", "review_rate": 4.6, "customer_name": "Robert Miller", "service_type": "Chimneyp Installation", "tech_name": "John D.", "review": "We had toll in for chimneyp installation after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Corona" },
{ "date": "2024-04-08", "review_rate": 4.7, "customer_name": "Robert Williams", "service_type": "Chimney Crown Repair", "tech_name": "Sam W.", "review": "I was impressed with the quality of service when I needed chimney crown repair. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Corona" },
{ "date": "2021-09-25", "review_rate": 5.0, "customer_name": "Christopher Miller", "service_type": "Chimney Repair", "tech_name": "Jake P.", "review": "I had some concerns with my chimney, so Illed for chimney repair. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Corona" },
{ "date": "2018-02-03", "review_rate": 5.0, "customer_name": "David Smith", "service_type": "Chimney Cleaning", "tech_name": "Mike S.", "review": "Our chimney needed chimney cleaning, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Corona" },
{ "date": "2024-11-06", "review_rate": 4.9, "customer_name": "Barbara Moore", "service_type": "Chimney Maintenance", "tech_name": "Tom B.", "review": "I was impressed with the quality of service when I needed chimney maintenance. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Corona" },
{ "date": "2022-05-12", "review_rate": 5.0, "customer_name": "Patricia Brown", "service_type": "Chimney Odor Elimination", "tech_name": "Jake P.", "review": "I had some concerns with my chimney, so Illed for chimney odor elimination. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Corona" },
{ "date": "2019-10-15", "review_rate": 4.7, "customer_name": "William Moore", "service_type": "Masonry Repair", "tech_name": "Mike S.", "review": "I was impressed with the quality of service when I needed masonry repair. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Corona" },
{ "date": "2021-05-28", "review_rate": 4.6, "customer_name": "Jessica Moore", "service_type": "Smoke Chamber Repair", "tech_name": "Mike S.", "review": "I had some concerns with my chimney, so Illed for smoke chamber repair. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Corona" },
{ "date": "2018-02-11", "review_rate": 4.6, "customer_name": "Linda Smith", "service_type": "Chimney Liner Installation", "tech_name": "John D.", "review": "I was impressed with the quality of service when I needed chimney liner installation. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Corona" },
{ "date": "2024-04-10", "review_rate": 4.6, "customer_name": "Sarah Williams", "service_type": "Chimney Maintenance", "tech_name": "Tom B.", "review": "I was impressed with the quality of service when I needed chimney maintenance. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Corona" },
{ "date": "2020-01-27", "review_rate": 5.0, "customer_name": "Susan Wilson", "service_type": "Creosote Removal", "tech_name": "Jake P.", "review": "I was impressed with the quality of service when I needed creosote removal. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Corona" },
{ "date": "2020-03-24", "review_rate": 4.9, "customer_name": "Jessica Taylor", "service_type": "Fireplace Damper Repair", "tech_name": "Steve R.", "review": "We had toll in for fireplace damper repair after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Corona" },
{ "date": "2024-07-02", "review_rate": 4.8, "customer_name": "Mary Wilson", "service_type": "Fireplace Cleaning", "tech_name": "Sam W.", "review": "I had some concerns with my chimney, so Illed for fireplace cleaning. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Corona" },
{ "date": "2021-02-03", "review_rate": 4.7, "customer_name": "Robert Moore", "service_type": "Chimney Sweep", "tech_name": "Tom B.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney sweep. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Corona" },
{ "date": "2018-05-23", "review_rate": 4.7, "customer_name": "Christopher Taylor", "service_type": "Chimney Draft Issues", "tech_name": "Mike S.", "review": "Our chimney needed chimney draft issues, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Corona" },
{ "date": "2018-05-25", "review_rate": 4.8, "customer_name": "Joseph Jones", "service_type": "Chimney Animal Removal", "tech_name": "Sam W.", "review": "Our chimney needed chimney animal removal, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Corona" },
{ "date": "2022-10-23", "review_rate": 4.7, "customer_name": "Patricia Miller", "service_type": "Chimney Relining", "tech_name": "Tom B.", "review": "I had some concerns with my chimney, so Illed for chimney relining. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Corona" },
{ "date": "2021-08-06", "review_rate": 4.7, "customer_name": "Jessica Johnson", "service_type": "Creosote Removal", "tech_name": "Tom B.", "review": "We had toll in for creosote removal after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Corona" },
{ "date": "2018-05-21", "review_rate": 4.9, "customer_name": "Sarah Moore", "service_type": "Chimney Repair", "tech_name": "Jake P.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney repair. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Corona" },
{ "date": "2019-09-25", "review_rate": 4.8, "customer_name": "Christopher Miller", "service_type": "Masonry Repair", "tech_name": "Mike S.", "review": "We had toll in for masonry repair after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Corona" },
{ "date": "2018-08-10", "review_rate": 4.8, "customer_name": "Barbara Brown", "service_type": "Chimneyp Installation", "tech_name": "Tom B.", "review": "I had some concerns with my chimney, so Illed for chimneyp installation. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Corona" },
{ "date": "2019-06-03", "review_rate": 4.7, "customer_name": "Susan Williams", "service_type": "Chimney Sweep", "tech_name": "John D.", "review": "I had some concerns with my chimney, so Illed for chimney sweep. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Corona" },
{ "date": "2021-04-12", "review_rate": 4.9, "customer_name": "William Davis", "service_type": "Chimney Draft Issues", "tech_name": "John D.", "review": "I had some concerns with my chimney, so Illed for chimney draft issues. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Corona" },
{ "date": "2024-12-07", "review_rate": 4.8, "customer_name": "Michael Wilson", "service_type": "Chimney Waterproofing", "tech_name": "Jake P.", "review": "I was impressed with the quality of service when I needed chimney waterproofing. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Corona" },
{ "date": "2018-10-07", "review_rate": 4.9, "customer_name": "William Taylor", "service_type": "Chimney Waterproofing", "tech_name": "Steve R.", "review": "We had toll in for chimney waterproofing after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Corona" },
{ "date": "2020-06-03", "review_rate": 4.8, "customer_name": "Richard Moore", "service_type": "Chimney Draft Issues", "tech_name": "John D.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney draft issues. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Corona" },
{ "date": "2022-06-19", "review_rate": 4.8, "customer_name": "James Smith", "service_type": "Masonry Repair", "tech_name": "Mike S.", "review": "I had some concerns with my chimney, so Illed for masonry repair. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Corona" },
{ "date": "2021-08-11", "review_rate": 4.7, "customer_name": "Christopher Johnson", "service_type": "Chimney Liner Installation", "tech_name": "John D.", "review": "I had some concerns with my chimney, so Illed for chimney liner installation. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Corona" },
{ "date": "2022-09-18", "review_rate": 5.0, "customer_name": "Barbara Brown", "service_type": "Chimney Relining", "tech_name": "Steve R.", "review": "Our chimney needed chimney relining, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Corona" },
{ "date": "2021-11-22", "review_rate": 4.7, "customer_name": "Elizabeth Wilson", "service_type": "Chimney Inspection", "tech_name": "Sam W.", "review": "I was impressed with the quality of service when I needed chimney inspection. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Corona" },
{ "date": "2023-12-03", "review_rate": 4.8, "customer_name": "Patricia Smith", "service_type": "Creosote Removal", "tech_name": "Tom B.", "review": "I had some concerns with my chimney, so Illed for creosote removal. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Corona" },
{ "date": "2023-08-03", "review_rate": 4.7, "customer_name": "Barbara Davis", "service_type": "Masonry Repair", "tech_name": "Mike S.", "review": "After noticing some performance issues with our chimney, we hired a local service for masonry repair. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Corona" },
{ "date": "2022-08-07", "review_rate": 4.8, "customer_name": "Patricia Davis", "service_type": "Chimney Sweep", "tech_name": "Tom B.", "review": "I was impressed with the quality of service when I needed chimney sweep. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Corona" },
{ "date": "2018-05-10", "review_rate": 4.7, "customer_name": "Richard Jones", "service_type": "Chimney Odor Elimination", "tech_name": "Sam W.", "review": "I had some concerns with my chimney, so Illed for chimney odor elimination. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Corona" },
{ "date": "2023-10-05", "review_rate": 4.8, "customer_name": "Nancy Brown", "service_type": "Chimney Sweep", "tech_name": "Tom B.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney sweep. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Corona" },
{ "date": "2023-01-27", "review_rate": 5.0, "customer_name": "Barbara Smith", "service_type": "Chimney Relining", "tech_name": "Jake P.", "review": "Our chimney needed chimney relining, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Corona" },
{ "date": "2019-08-04", "review_rate": 4.9, "customer_name": "Patricia Brown", "service_type": "Chimney Crown Repair", "tech_name": "Mike S.", "review": "Our chimney needed chimney crown repair, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Corona" },
{ "date": "2022-08-20", "review_rate": 4.6, "customer_name": "Charles Moore", "service_type": "Chimney Inspection", "tech_name": "Steve R.", "review": "I had some concerns with my chimney, so Illed for chimney inspection. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Corona" },
{ "date": "2024-10-06", "review_rate": 4.8, "customer_name": "David Jones", "service_type": "Chimney Liner Installation", "tech_name": "Sam W.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney liner installation. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Corona" },
{ "date": "2018-05-24", "review_rate": 5.0, "customer_name": "Patricia Miller", "service_type": "Chimney Crown Repair", "tech_name": "Steve R.", "review": "We had toll in for chimney crown repair after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Corona" },
{ "date": "2020-03-24", "review_rate": 4.7, "customer_name": "Nancy Davis", "service_type": "Chimney Animal Removal", "tech_name": "John D.", "review": "Our chimney needed chimney animal removal, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Corona" },
{ "date": "2020-07-14", "review_rate": 4.6, "customer_name": "Karen Miller", "service_type": "Chimney Inspection", "tech_name": "Mike S.", "review": "I had some concerns with my chimney, so Illed for chimney inspection. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Corona" },
{ "date": "2020-12-11", "review_rate": 4.9, "customer_name": "Joseph Miller", "service_type": "Fireplace Cleaning", "tech_name": "Sam W.", "review": "After noticing some performance issues with our chimney, we hired a local service for fireplace cleaning. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Corona" },
{ "date": "2022-11-22", "review_rate": 5.0, "customer_name": "Sarah Smith", "service_type": "Chimney Odor Elimination", "tech_name": "Mike S.", "review": "I was impressed with the quality of service when I needed chimney odor elimination. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Corona" },
{ "date": "2020-09-05", "review_rate": 4.6, "customer_name": "Elizabeth Jones", "service_type": "Chimney Relining", "tech_name": "Tom B.", "review": "We had toll in for chimney relining after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Corona" },
{ "date": "2020-02-22", "review_rate": 4.6, "customer_name": "Patricia Wilson", "service_type": "Chimney Cleaning", "tech_name": "John D.", "review": "We had toll in for chimney cleaning after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Corona" },
{ "date": "2018-12-22", "review_rate": 4.6, "customer_name": "Karen Wilson", "service_type": "Chimney Leak Detection", "tech_name": "Jake P.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney leak detection. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Corona" },
{ "date": "2020-12-10", "review_rate": 4.7, "customer_name": "Mary Jones", "service_type": "Smoke Chamber Repair", "tech_name": "Jake P.", "review": "I had some concerns with my chimney, so Illed for smoke chamber repair. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Corona" },
{ "date": "2021-08-01", "review_rate": 4.8, "customer_name": "Thomas Williams", "service_type": "Chimney Inspection", "tech_name": "Jake P.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney inspection. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Corona" },
{ "date": "2022-10-21", "review_rate": 4.7, "customer_name": "Linda Miller", "service_type": "Chimney Crown Repair", "tech_name": "Sam W.", "review": "I was impressed with the quality of service when I needed chimney crown repair. The team did a fantastic job and made sure I understood the work that was being done. They also offered some valuable tips on maintaining the chimney, which I appreciated. I’m very satisfied with the results and will definitely use this company again..", "location": "Corona" },
{ "date": "2019-06-06", "review_rate": 4.7, "customer_name": "Sarah Jones", "service_type": "Chimney Maintenance", "tech_name": "Steve R.", "review": "Our chimney needed chimney maintenance, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Corona" },
{ "date": "2022-02-18", "review_rate": 4.9, "customer_name": "Nancy Miller", "service_type": "Creosote Removal", "tech_name": "Tom B.", "review": "We had toll in for creosote removal after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Corona" },
{ "date": "2022-02-20", "review_rate": 4.6, "customer_name": "Sarah Miller", "service_type": "Chimney Sweep", "tech_name": "John D.", "review": "Our chimney needed chimney sweep, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Corona" },
{ "date": "2021-07-04", "review_rate": 4.9, "customer_name": "Patricia Miller", "service_type": "Chimney Liner Installation", "tech_name": "Sam W.", "review": "We had toll in for chimney liner installation after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Corona" },
{ "date": "2020-10-07", "review_rate": 4.9, "customer_name": "Sarah Johnson", "service_type": "Chimney Inspection", "tech_name": "Sam W.", "review": "Our chimney needed chimney inspection, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Corona" },
{ "date": "2023-10-01", "review_rate": 4.9, "customer_name": "Barbara Davis", "service_type": "Chimney Safety Inspections", "tech_name": "Sam W.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney safety inspections. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Corona" },
{ "date": "2022-05-26", "review_rate": 5.0, "customer_name": "Susan Brown", "service_type": "Chimney Crown Repair", "tech_name": "John D.", "review": "I had some concerns with my chimney, so Illed for chimney crown repair. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Corona" },
{ "date": "2018-07-16", "review_rate": 4.9, "customer_name": "Michael Davis", "service_type": "Chimney Leak Detection", "tech_name": "Steve R.", "review": "I had some concerns with my chimney, so Illed for chimney leak detection. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Corona" },
{ "date": "2019-01-08", "review_rate": 4.9, "customer_name": "Nancy Jones", "service_type": "Masonry Repair", "tech_name": "Mike S.", "review": "Our chimney needed masonry repair, so we contacted a professional. The level of service was outstanding. They arrived on time, explained the issue thoroughly, and completed the job quickly. The cleanup afterward was impeccable, and the pricing was transparent. I was very happy with the professionalism and the outcome. I would absolutely recommend them for any chimney needs!.", "location": "Fontana" },
{ "date": "2022-03-13", "review_rate": 4.7, "customer_name": "James Smith", "service_type": "Fireplace Damper Repair", "tech_name": "Mike S.", "review": "I had some concerns with my chimney, so Illed for fireplace damper repair. The technician did an excellent job and really put my mind at ease. The entire process was seamless from start to finish. Everything was done efficiently, and the chimney is in great shape now. I feel much safer using my fireplace!.", "location": "Fontana" },
{ "date": "2021-11-25", "review_rate": 4.9, "customer_name": "Michael Wilson", "service_type": "Chimney Maintenance", "tech_name": "Mike S.", "review": "After noticing some performance issues with our chimney, we hired a local service for chimney maintenance. They were thorough, professional, and very friendly. The work was done efficiently, and now the chimney is working perfectly. I would recommend this service to anyone needing reliable chimney maintenance..", "location": "Fontana" },
{ "date": "2018-06-17", "review_rate": 4.6, "customer_name": "William Wilson", "service_type": "Chimney Repointing", "tech_name": "Jake P.", "review": "We had toll in for chimney repointing after noticing some issues. The technician handled everything perfectly, explaining the issues with the chimney clearly and providing solutions. The work was done within the same day, and now I feel much more confident in the safety of our chimney and fireplace. The service exceeded my expectations!.", "location": "Fontana" },
]

export default LocationReviewsData;
