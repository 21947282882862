import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import './HomePageGoogle.scss';
import { useLocation, useNavigate } from "react-router-dom";
import Faq from "react-faq-component";
import axios from 'axios';

// images
import AreaData from '../../Components/ServicesPage/AreaData'
import LocationReviewsData from '../LocationRivews/LocationReviewsData'
import BookAnAppointment from "../BookAnAppointment/BookAnAppointment";
import emailjs from 'emailjs-com';

export default function HomePageGoogle({ locationsMain, CitiePhone, CitiesData }) {
    const [input, setInput] = useState('how to fix garage door');
    const [response, setResponse] = useState({});
    const location = useLocation()
    const navigate = useNavigate()
    const [FormSubmiting, setFormSubmiting] = useState(false);
    const url = new URL(window.location.href);
    function sendEmail(e) {
        // setFormSubmiting(true)
        setFormSubmiting(true)
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm('service_fqud6um', 'template_vdkxpnd', e.target, 'QeqW-bXHRNjRmhZ6I')
            .then((result) => {
                setFormSubmiting(false)
                navigate(`/thankscontact?url=${location.pathname + location.search}`)
            }, (error) => {
                console.log(error.text);
            });
    }
    const data = {
        title: "",
        rows: [

            {
                title: "Why choose BHO Chimney Sweep?",
                content: "With 50 years of expertise, we provide professional, reliable chimney services, ensuring safety and efficiency in every home."
            },
            {
                title: "What is BHO stands for?",
                content: "BHO stands for Bob and Henry Oliver, the founders who built the company with a focus on quality chimney services, reflecting decades of trusted service to homes."
            },
            {
                title: "What services do you offer?",
                content: "We offer chimney cleaning, inspection, repairs, maintenance, creosote removal, waterproofing, and more for optimal performance."
            },
            {
                title: "How much is chimney inspection?",
                content: "Our chimney inspection costs $69. It includes a comprehensive check to identify potential issues."
            },
            {
                title: "Can you clean and repair together?",
                content: "Yes, we offer same-day cleaning and repair services during your appointment if needed."
            },
            {
                title: `Do you provide services surrounding ${CitiesData && CitiesData.City ? CitiesData.City : "Your Area"}?`,
                content: `Yes, we don't just serve ${CitiesData && CitiesData.City ? CitiesData.City : "Your Area"}! We cover a wide area, including a 75-mile radius around ${CitiesData && CitiesData.City ? CitiesData.City : "Your Area"}. Whether you're in nearby towns or surrounding areas, we're here to help.`
            },
            {
                title: "How do I book an appointment?",
                content: `Call us <a href='tel:${CitiePhone}'>${CitiePhone}</a> or fill out the <a href='/book${location.search}'}>online form</a> to schedule your visit.`
            },

        ]
    };
    const styles = {
        bgColor: '#eceef6',
        titleTextColor: 'white',
        titleTextSize: '18px',
        rowTitleColor: '#2a1768',
        rowTitleTextSize: '21px',
        rowContentColor: '#242424',
        rowContentTextSize: '16px',
        // rowContentTextWeight: '600',
        rowContentPaddingTop: '10px',
        rowContentPaddingBottom: '10px',
        rowContentPaddingLeft: '5px',
        rowContentPaddingRight: '5px',
        arrowColor: "#2a1768",
        //transitionDuration: "1s",
        // timingFunc: "ease"
    };
    const config = {
        // animate: true,
        // arrowIcon: "V",
        // tabFocus: true
        openOnload: 0,
    };

    useEffect(() => {
        (async () => {
            try {
                const searchKeyword = `I need from you 32 words about the "${url.searchParams.get('kd')}" how we "bho chimney sweep" can help our clients with that kind of service, give me that as json file: title 3 words (include in the title words from the keyword, remove null and bho chimmney sweep from title)(if no keywords just do for chimney sweep) and body`
                const apiKey = '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'; // Replace with your actual API key
                const resChat = await fetch(`https://api.openai.com/v1/chat/completions`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${apiKey}`,
                    },
                    body: JSON.stringify({
                        'model': 'gpt-3.5-turbo',
                        'messages': [
                            {
                                'role': 'user',
                                'content': searchKeyword
                            }
                        ]
                    })
                })
                const dataChat = await resChat.json()

                setResponse(JSON.parse(dataChat.choices[0].message.content))
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);


    return (
        <div className="HomePageGoogle" id="homepage">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>BHO Chimney Sweep | Expert Chimney Cleaning, Inspection & Services | {CitiePhone}</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Chimney Sweep | Expert Chimney Cleaning, Inspection & Services | {CitiePhone}" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Chimney Sweep | Expert Chimney Cleaning, Inspection & Services | {CitiePhone}" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.bhochimneysweep.com/" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Chimney Sweep provides top-quality chimney cleaning, inspections, and repairs to keep your home safe and efficient. Trust our experienced professionals for all your chimney service needs." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Chimney Sweep provides top-quality chimney cleaning, inspections, and repairs to keep your home safe and efficient. Trust our experienced professionals for all your chimney service needs." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Chimney Sweep" data-react-helmet="true" />
                <meta name="keywords" content="chimney sweep, chimney Inspection, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, chimney services, chimney service, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Insulation, Chimney Installation, Chimney Liner Repair, Chimney Pointing, Chimney Rainp Installation, Chimney Repair, Chimney Crown Repair, Chimney Restoration, Chimney Flue Installation, Chimney Flue Repair, ​Chimney Construction, Chimney Vent Installation, Chimney Flashing, Flexible Chimney Liner Installation, Chimney Damper Repair, Chimneyp Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rainp Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplacep" data-react-helmet="true" />
            </Helmet>
            {/* MainBanner */}
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>The Chimney Experts You've Trusted for Over 50 Years In {CitiesData && CitiesData.City ? CitiesData.Full : "Your Area"}</h1>
                    <h2>Servicing <b>{CitiesData && CitiesData.City ? CitiesData.City.toUpperCase() : "Your Area"}</b> And Surrounding Areas • <span>{url.searchParams.get('kd') ? url.searchParams.get('kd').includes('near') ? url.searchParams.get('kd').replace('near me', '•') : url.searchParams.get('kd') + "," : ""}</span> Chimney Inspections, Repair And Cleaning Near Me</h2>
                    {/* <h2>Servicing <b>{CitiesData && CitiesData.City? CitiesData.City.toUpperCase() : "Your Area"}</b> And Surrounding Areas - Chimney Inspections, Repairs And Cleaning Near Me</h2> */}
                    {/* <h2>Chimney Services, Repairs And Cleaning Near Me</h2> */}
                    {/* <h2>Chimney Cleaning Near Me</h2> */}
                    <h3><img src={require(`../../Images/checklist.png`)} alt="Locally Owned & Operated BHO Chimney Sweep Repair" />Locally Owned & Operated</h3>
                    <h3><img src={require(`../../Images/checklist.png`)} alt="Licensed | Bonded | Insured BHO Chimney Sweep Repair" />Certified | Licensed | Insured</h3>
                    <h3><img src={require(`../../Images/checklist.png`)} alt="Satisfaction Guaranteed BHO Chimney Sweep Repair" />Satisfaction Guaranteed</h3>
                    <div className="MainBannerLeftButtons">
                        <a href={`tel:${CitiePhone}`}>Call Us Now!</a>
                        <a href={`tel:${CitiePhone}`}>{CitiePhone}</a>
                        <a onClick={() => navigate(`/book${location.search}`)}>SCHEDULE ONLINE<img src={require(`../../Images/book.png`)} alt="Chimney Sweep Cleaning Near Me BHO" /></a>
                    </div>
                </div>
                <div className="MainBannerRight">
                    <img fetchpriority="low" src={require(`../../ImagesLogos/Banner1Logo.png`)} alt="Chimney Sweep BHO | Chimney Repair BHO | Chimney Services BHO | Chimney Inspection BHO" />
                    <div className="MainBannerRightBubble1"></div>
                    <div className="MainBannerRightBubble2"></div>
                </div>
            </div>
            {/* BookAnAppointmentMainComponentEmergery */}
            <div className="BookAnAppointmentMainComponentEmergery">
                <div className="BookAnAppointmentMainComponentRates">
                    <span className="GoogleButt">
                        <h6>5 STAR - Based On 1,836 Reviews</h6>
                    </span>
                    <div className="BookAnAppointmentMainComponentRatesImages">
                        <img src={require(`../../Images/YELP.png`)} alt="BHO Chimney Sweep Cleaning Yelp Review" />
                        <img src={require(`../../Images/Angie.png`)} alt="BHO Chimney Sweep Cleaning Angie Review" />
                        <img src={require(`../../Images/HomeAdvisor.png`)} alt="BHO Chimney Sweep Cleaning HomeAdvisor Review" />
                        <img src={require(`../../Images/BBB.png`)} alt="BHO Chimney Sweep Cleaning BBB Review" />
                        <img src={require(`../../Images/Google.png`)} alt="BHO Chimney Sweep Cleaning Google Review" />
                        <img src={require(`../../Images/Porch.png`)} alt="BHO Chimney Sweep Cleaning Porch Review" />
                        <img src={require(`../../Images/Trust.png`)} alt="BHO Chimney Sweep Cleaning Trust Review" />
                    </div>
                    <h1>Customer Testimonials</h1>
                    <h2>⭐⭐⭐⭐⭐</h2>
                    <h3>
                        I’ve used BHO Chimney Sweep for <span>{url.searchParams.get('kd') ? url.searchParams.get('kd').includes('near') ? url.searchParams.get('kd').replace('near me', ',') : url.searchParams.get('kd') + "," : "Maintenance"}</span> and others repairs & cleaning, and I’m always impressed with their level of service. They use top-quality materials and are very detail-oriented. My chimney has never been in better shape, and I appreciate their commitment to safety and customer satisfaction.
                        <br />
                        <br />
                        <a onClick={() => window.location.hash = 'reviews'}>MORE FROM OUR CUSTOMERS &gt;</a>
                    </h3>

                </div>
                <div className="BookAnAppointmentMainComponentEmergeryBox">
                    <a href={`tel:${CitiePhone}`}>For emergency service Call: {CitiePhone}</a>
                </div>
            </div>
            {/* MainBanner2Top2 */}
            <div className="MainBanner2Top2" id="ourservices">
                <div className="MainBanner2TopBox">
                    <div className="MainBanner2TopBoxBack">
                        {/*  */}
                    </div>
                    <div className="MainBanner2TopBoxMain">
                        <h2>OUR CHIMNEY SERVICES</h2>
                        <div className="MainBanner2TopBoxMenu">
                            <a className="MainBanner2TopBoxBox">
                                <img src={require(`../../Images/chimneycleaning.png`)} alt="Chimney Cleaning" />
                                <h3>Chimney Cleaning</h3>
                                <h4>and Fireplace Cleaning</h4>
                                <h2>Remove creosote buildup and debris for a safer, more efficient fireplace and improved indoor air quality.</h2>
                            </a>
                            <a className="MainBanner2TopBoxBox">
                                <img src={require(`../../Images/chimneyinspection.png`)} alt="Chimney Cleaning" />
                                <h3>Chimney Inspection</h3>
                                <h4>and Fireplace Inspection</h4>
                                <h2>Ensure your chimney’s structural integrity and functionality with a thorough inspection to prevent hazards and issues.</h2>
                            </a>
                            <a className="MainBanner2TopBoxBox">
                                <img src={require(`../../Images/chimneymaintenance.png`)} alt="Chimney Cleaning" />
                                <h3>Chimney Maintenance</h3>
                                <h4>and Fireplace Maintenance</h4>
                                <h2>Regular maintenance prolongs chimney life, ensuring safety, efficiency, and preventing costly future repairs.</h2>
                            </a>
                            <a className="MainBanner2TopBoxBox">
                                <img src={require(`../../Images/chimneyrepair.png`)} alt="Chimney Cleaning" />
                                <h3>Chimney Repair</h3>
                                <h4>and Fireplace Repair</h4>
                                <h2>Fix cracks, leaks, Chimney Cap, Gas Fireplace Repair and structural issues to restore your chimney’s safety, functionality, and aesthetic appeal.</h2>
                            </a>
                        </div>
                        <div className="MainBanner2TopBoxMainChat">
                            <img src={require(`../../ImagesLogos/Image1Logo.png`)} />
                            <div className="MainBanner2TopBoxMainChatText">
                                <h4>{response.title}</h4>
                                <h3>{response.body}</h3>
                                <h5>Call us at <a href={`tel:${CitiePhone}`}>{CitiePhone}</a> or fill out the <a href={`/book${location.search}`}>online form</a> to schedule your visit.</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* MainBanner2 */}
            <div className="MainBanner2" id="about">
                <div className="MainBanner2TopTop">
                    <div className="MainBanner2TopTopLeft">
                        <img src={require(`../../Images/Chimney3.jpeg`)} alt="Chimney Sweep BHO | Chimney Repair BHO | Chimney Services BHO | Chimney Inspection BHO" />
                    </div>
                    <div className="MainBanner2TopTopRight">
                        <h4>LEAVE CHIMNEY TO THE PROS</h4>
                        <h5>BECAUSE WITH BHO CHIMNEY SWEEP, YOU GET MORE THAN JUST A SERVICE—YOU GET PEACE OF MIND.</h5>
                        <div className="MainBanner2TopBox">
                            <h2>REPUTATION</h2>
                            <h3>Trusted chimney experts since 1975 in {CitiesData && CitiesData.City ? CitiesData.City : "Your Area"}. Delivering exceptional service and craftsmanship with decades of proven expertise.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>TRANSPARENCY</h2>
                            <h3>No hidden fees—our honest pricing covers chimney services any time, including holidays.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>EFFICIENCY</h2>
                            <h3>Prompt same-day and emergency chimney services, available 365 days a year to meet your needs.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>QUALITY</h2>
                            <h3>Comprehensive chimney, from cleaning to repair, using advanced techniques and the latest equipment.</h3>
                        </div>
                    </div>
                </div>
                {/* Title */}
                <div className="MainBanner2TopTopBtn">
                    <div className="MainBanner2TopTopBtnLeft">
                        <img src={require(`../../Images/tag.png`)} alt="" />
                        <span>
                            <h1>$69 Chimney Inspection Service</h1>
                            <h2>Chimney cleaning in <b>{CitiesData && CitiesData.City ? CitiesData.City.toUpperCase() : "Your Area"}</b> is not merely about appearances; it's a crucial step in safeguarding your home's air quality and overall safety. Regular cleaning reduces the buildup of harmful contaminants and ensures that your chimney operates efficiently, minimizing potential fire hazards.</h2>
                        </span>
                    </div>
                    <div className="MainBanner2TopTopBtnRight">
                        <a onClick={() => navigate(`/book${location.search}`)}>SCHEDULE ONLINE<img src={require(`../../Images/book.png`)} alt="Chimney Sweep BHO | Chimney Repair BHO | Chimney Services BHO | Chimney Inspection BHO" /></a>
                        <a href={`tel:${CitiePhone}`}>CALL NOW {CitiePhone}</a>
                    </div>
                </div>
            </div>
            {/* BookAnAppointment */}
            <BookAnAppointment response={response} CitiesData={CitiesData} CitiePhone={CitiePhone} />
            {/* MainBanner3 */}
            <div className="MainBanner3">
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Chimney Cleaning</h2>
                            <h3>
                                Exceptional Chimney And Fireplace Cleaning Services in {CitiesData && CitiesData.City ? CitiesData.City : "Your Area"}
                                <br />
                                <br />
                                Chimney cleaning services, Maintaining a clean chimney is essential for the safety and efficiency of your fireplace or stove. Over time, soot, creosote, and other debris accumulate inside your chimney, posing fire hazards and reducing efficiency. Our expert technicians are committed to ensuring your chimney remains clean and safe for use.
                                <br />
                                <br />
                            </h3>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={require(`../../Images/Chimney6.jpeg`)} alt="Chimney Cleaning BHO" />
                        </div>
                    </div>
                    <div className="MainBanner2BottomBox">

                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Chimney Inspection</h2>
                            <h3>
                                Exceptional Chimney And Fireplace Inspection
                                <br />
                                <br />
                                Regular chimney and fireplace inspections are crucial for the safety and efficiency of your fireplace or stove. Over time, issues such as soot buildup, creosote accumulation, and structural concerns can pose significant fire hazards and reduce efficiency. Our expert technicians are dedicated to thoroughly inspecting your chimney and fireplace to ensure they remain safe and in optimal condition for use.
                                <br />
                                <br />
                            </h3>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={require(`../../Images/Chimney4.jpeg`)} alt="Chimney Maintenance BHO" />
                        </div>
                    </div>
                </div>
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Chimney Repair</h2>
                            <h3>
                                Professional Chimney And Fireplace Repair Services
                                <br />
                                <br />
                                At BHO Chimney Sweep, we offer expert chimney repair services to homeowners. A well-maintained chimney is essential for the safety and efficiency of your fireplace or stove. Over time, your chimney can suffer from wear and tear, weather damage, flashing issues, leaks, and cracks that compromise its integrity. Our skilled technicians are here to provide comprehensive chimney repairs, including flashing repair, leak resolution, and crack sealing, ensuring your chimney remains safe and functional.
                                <br />
                                <br />
                            </h3>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={require(`../../Images/Chimney2.jpeg`)} alt="Chimney Inspection BHO" />

                        </div>
                    </div>
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Gas Fireplace Service</h2>
                            <h3>
                                Fireplace Installation, Repair, and Maintenance
                                <br />
                                <br />
                                At BHO Fireplace Services, we offer expert solutions for all your fireplace needs. From gas fireplace repairs and installation to inspections, maintenance, and pilot light fixes, our skilled technicians ensure safe and efficient operation year-round. Whether it's a new installation or troubleshooting why your pilot light won’t stay lit, we’ve got you covered!
                                <br />
                                <br />
                            </h3>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={require(`../../Images/Chimney9.png`)} alt="Chimney Repair BHO" />

                        </div>
                    </div>

                </div>
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Chimney Masonry</h2>
                            <h3>
                                Quality Chimney And Fireplace Rebuilding Experts
                                <br />
                                <br />
                                Chimney masonry involves building and maintaining brick, stone, or concrete chimneys. Weather, moisture, and heat can cause cracks, spalling, and deterioration over time. BHO Chimney Sweep offers expert chimney rebuilding, tuckpointing, waterproofing, and crown repairs to ensure safety and durability. Trust BHO for reliable service and lasting chimney solutions.
                                <br />
                                <br />
                            </h3>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={require(`../../Images/Chimney1.jpeg`)} alt="Chimney Cleaning BHO" />

                        </div>
                    </div>
                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Chimney Cap</h2>
                            <h3>
                                Chimney Cap Repair, Installation, Replacement
                                <br />
                                <br />
                                Proper chimney cap repair, installation, and replacement are essential for protecting your chimney from weather damage, debris, and animal intrusion. Over time, chimney caps can become damaged or worn, compromising their effectiveness. Our skilled technicians specialize in repairing damaged caps, installing new ones, or replacing old caps to ensure your chimney remains safe, functional, and protected.
                                <br />
                                <br />
                            </h3>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={require(`../../Images/chimneycap.png`)} alt="Chimney Repair BHO" />
                        </div>
                    </div>
         
                </div>
                <div className="MainBanner2Bottom">

                    <div className="MainBanner2BottomBox">
                        <div className="MainBanner2BottomBoxLeft">
                            <h2>Chimney Maintenance</h2>
                            <h3>
                                Top-Notch Chimney And Fireplace Maintenance Services
                                <br />
                                <br />
                                At BHO Chimney Sweep, we specialize in providing comprehensive chimney maintenance services to homeowners. Regular chimney maintenance is essential for the safety, efficiency, and longevity of your fireplace or stove. Our skilled technicians are dedicated to keeping your chimney in optimal condition, ensuring that it operates safely and efficiently year-round.
                                <br />
                                <br />
                            </h3>
                        </div>
                        <div className="MainBanner2BottomBoxRight">
                            <img src={require(`../../Images/Chimney3.jpeg`)} alt="Chimney Repair BHO" />

                        </div>
                    </div>
                    <div></div>
                </div>
            </div>
            {/* MainBanner5 */}
            <div className="MainBanner5">
                <div className="MainBanner5Left">
                    <h1>HOW TO GET IN TOUCH WITH BHO CHIMNEY SWEEP?</h1>
                    <div className="MainBanner5LeftBox">
                        <h2>1</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Reach Out to Us</h3>
                            <h4>Contact our expert team by phone or visit our <b onClick={() => navigate(`/book${location.search}`)}>book an appointment page</b> to schedule your Chimney Sweep service.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>2</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Provide Your Details</h3>
                            <h4>Share your address and any specific details about your home that could impact the service, ensuring our team is well-prepared.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>3</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Schedule Your Service</h3>
                            <h4>Discuss the urgency of your needs, and we’ll arrange a convenient date and time for our team to visit.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>4</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Receive a Customized Quote</h3>
                            <h4>Our local service technician will assess your requirements and provide a quote based on your chimney.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftAbutton">

                        <a href={`tel:${CitiePhone}`}>CALL NOW {CitiePhone}</a>
                        <a onClick={() => navigate(`/book${location.search}`)}>SCHEDULE ONLINE</a>
                    </div>

                </div>
                <div className="MainBanner5Right">
                    <img src={require(`../../ImagesLogos/Image2Logo.png`)} alt="Air Duct Cleaning Near Me BHO" />
                    <div className="MainBanner5RightBubble1"></div>
                    <div className="MainBanner5RightBubble2"></div>
                </div>

            </div>
            {/* MainBanner6 */}
            <div className="MainBanner6">
                <div className="MainBanner6Left">
                    <h2>GOT QUESTIONS? WE'VE GOT ANSWERS.</h2>
                    <h3>Feel Free To Reach Out To Us By Phone. For The Fastest Response, We Are Available By Phone 24/7 And Ready To Provide You With A Complimentary Consultation.</h3>
                    <a className="MainBanner6LeftContact" onClick={() => window.location.hash = 'contact'}>CONTACT US</a>
                    <div className="MainBanner4FaqMore">
                        <Faq
                            data={data}
                            styles={styles}
                            config={config}
                        />
                    </div>
                </div>
                <div className="MainBanner6Right">
                    <img src={require(`../../Images/bgnobgs.png`)} alt="Chimney Sweep BHO | Chimney Repair BHO | Chimney Services BHO | Chimney Inspection BHO" />
                </div>
            </div>
            {/* MainBanner7 */}
            <div className="MainBanner7" id='reviews'>
                <h1>Real Testimonials from Our Valued Client in {CitiesData && CitiesData.City ? CitiesData.City : "Your Area"}</h1>
                <div className="LocationReviewsLsit">
                    <div className="LocationReviewsLsitUnder">
                        {LocationReviewsData.slice(0, 36).map((A, index) =>
                            <div key={index} className="LocationReviewsBox">
                                <div className="LocationReviewsBoxLeft">
                                    {/* <h3>Open Now 24/7 • Top Rated • Same Day Service</h3> */}
                                    <span>
                                        <img src={require(`../../Images/star.png`)}></img>
                                        <img src={require(`../../Images/star.png`)}></img>
                                        <img src={require(`../../Images/star.png`)}></img>
                                        <img src={require(`../../Images/star.png`)}></img>
                                        <img src={require(`../../Images/star.png`)}></img>
                                        <img src={require(`../../Images/checklist.png`)}></img>
                                    </span>
                                    <h2><img src={require(`../../Images/GoogleIcons.png`)}></img>Rated 5 By {A.customer_name}</h2>
                                    <h4>{A.review}</h4>
                                    <div className="LocationReviewsBoxLeftBox">
                                        <div className="LocationReviewsBoxLeftBoxBox">
                                            <h5>Service Provided:</h5>
                                            <h6>{A.service_type}</h6>
                                        </div>
                                        <div className="LocationReviewsBoxLeftBoxBox">
                                            <h5>Technician</h5>
                                            <h6>{A.tech_name}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="LocationReviewsLsitShodowR"></div>
                </div>
            </div>
            {/* locationsMain */}
            {locationsMain.find(a => a.Phone == CitiePhone) ?
                <div className="HomePageGoogleLocation">
                    <div className="HomePageGoogleLocationLeft">
                        <h1>Our Service Area / Locations</h1>
                        <h2>Servicing <b>{CitiesData && CitiesData.City ? CitiesData.Full.toUpperCase() : "Your Area"}</b> And Surrounding Areas</h2>
                        <h3>75-Radius Arround {CitiesData && CitiesData.City ? CitiesData.City.toUpperCase() : "Your Area"}</h3>
                        {locationsMain.find(a => a.Phone == CitiePhone).Address.map((A, index) =>
                            <div key={index} className="HomePageGoogleLocationLeftBox">
                                <h4>{A.Location1}</h4>
                                <h5>{A.Address1}</h5>
                                <a href={`tel:${CitiePhone}`}>{CitiePhone}</a>
                            </div>
                        )}
                    </div>
                    <div className="HomePageGoogleLocationRight">
                        <div className="HomePageGoogleLocationRightBorder"></div>
                        <iframe src={locationsMain.find(a => a.Phone == CitiePhone).Map} ></iframe>
                    </div>
                </div>
                : null}
            {/* MainBanner8 */}
            <div className="MainBanner8" id="contact">
                <div className="MainBanner8Left">
                    <h1>Get In Touch</h1>
                    <h2>Is There Anything Else You'd Like To Know?</h2>
                    <h3>We Are Available Via Phone Or By This Form. However If You Would Like A Quick Response We Are By Our Phones 24/7 To Give You A Phone Consultation Free Of Charge.</h3>
                    <a href={`tel:${CitiePhone}`}>Click To Call Us Now!</a>
                    <a onClick={() => navigate(`/book${location.search}`)}>SCHEDULE ONLINE<img src={require(`../../Images/book.png`)} alt="Chimney Cleaning Near Me BHO" /></a>
                </div>
                <div className="MainBanner8Right">

                    <div className="ContactFirstBody">
                        <form className="contact-form" onSubmit={sendEmail}>
                            <h4>Don't Hesitate To Reach Out!</h4>
                            <h5>If you need help with a new service or have questions about an existing one, please reach out via phone or by this form. Our team is here to assist you!</h5>
                            <h2>Full Name</h2>
                            <input type="text" name="Full_Name" required />
                            <h2>Address</h2>
                            <input type="text" name="Country" required />
                            <div className='ContactFirsthhh'>
                                <div className="ContactFirsthhhBox">
                                    <h2>Phone</h2>
                                    <input type="tel" name="Phone" required />
                                </div>

                            </div>
                            <h2>Massage</h2>
                            <textarea className="Message" name="Message" id="" cols="30" rows="10" required></textarea>
                            {
                                FormSubmiting ?
                                    <button class="buttonload">
                                        <i class="fa fa-refresh fa-spin"></i>Loading
                                        {/* <input type="submit" value="SEND" /> */}
                                    </button>
                                    :
                                    null
                            }
                            {
                                !FormSubmiting ?
                                    <span>
                                        <input type="submit" value="CLICK TO SEND" />
                                        <input type="hidden" name="Company_From" defaultValue={`BHO Chimney Sweep | BHO`} />
                                        <input type="hidden" name="Company" defaultValue={`BHO Chimney Sweep | BHO`} />
                                    </span>
                                    :
                                    null
                            }


                        </form>
                    </div>
                </div>
            </div>
            {/* MainBanner4 */}
            <div className="MainBanner4">
                <div className="MainBanner4Right">
                    <h1>OUR CHIMNEY SERVICES</h1>
                    <h2>At BHO Chimney Sweep, we provide a full range of chimney services, including cleaning, inspection, repair, and maintenance. Our certified technicians are dedicated to ensuring your chimney is safe, efficient, and in optimal condition. We use only high-quality materials and cutting-edge equipment to deliver outstanding results, enhancing both the safety and comfort of your home while giving you peace of mind.</h2>
                    <div className="MainBafnner4RightBenefits">
                        {AreaData.map((A, index) =>
                            <a key={index} href={`${A.Path}${location.search}`} className="Header1BoxMenuBox">
                                {A.TitleShortVery}
                            </a>
                        )}
                        <a className="Header1BoxMenuBox">
                            Gas Fireplace Repair
                        </a>
                    </div>
                </div>
            </div>
            {/* HomePageGoogleFooter */}
            <div className="HomePageGoogleFooter">
                <div className="HomePageGoogleFooterTop">
                    <div className="HomePageGoogleFooterTopTitle">
                        <img src={require(`../../Images/Music.png`)} />
                        <h1>BHO Sweep Clean And Neat<br />Can’t Be Beat!</h1>
                        <img src={require(`../../Images/Music.png`)} />
                    </div>
                    <div className="HomePageGoogleFooterTopImg">
                        <img src={require(`../../ImagesLogos/Image1Logo.png`)} />
                    </div>
                </div>
                <div className="HomePageGoogleFooterBottom">
                    <div className="HomePageGoogleFooterBottomTop">
                        <img src={require(`../../ImagesLogos/Banner2Logo.png`)} />
                        <p>Copyright 1975-2024 BHO Chimney Sweep. All rights reserved. All available services, hours of operations, pricing structure, and guarantees may vary by location.</p>
                        <div className="SiteMapDiv">
                            <h1>Privacy</h1>
                            <a href="/privacy-policy">Privacy Policy</a>
                            <a href="/terms-and-conditions">Terms And Conditions</a>
                        </div>
                    </div>
                    {locationsMain.find(a => a.Phone == CitiePhone) ?
                        <div className="HomePageGoogleFooterMiddle">
                            {locationsMain.find(a => a.Phone == CitiePhone).Address.map((A, index) =>
                                <div key={index} className="HomePageGoogleLocationLeftBox">
                                    <h4>{A.Location1}</h4>
                                    <h5>{A.Address1}</h5>
                                    <a href={`tel:${CitiePhone}`}>{CitiePhone}</a>
                                </div>
                            )}
                        </div>
                        : null}
                    <div className="HomePageGoogleFooterBottomBottom">
                        <span>
                            <img src={require(`../../Images/tag.png`)} alt="" />
                            <a>www.bhochimneysweep.com</a>
                        </span>
                        <span>
                            <img src={require(`../../Images/tag.png`)} alt="" />
                            <a href={`tel:${CitiePhone}`}>{CitiePhone}</a>
                        </span>
                        <span>
                            <img src={require(`../../Images/tag.png`)} alt="" />
                            <a>info@bhochimneysweep.com</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

